import CodeExample from "components/docs/code-examples/CodeExample";
import HeaderBar from "components/docs/layout/HeaderBar";
import LINKS from "components/docs/navigation/links";
import { APICategory } from "@merge-api/merge-javascript-shared";
import ImgFrameSection from "components/docs/sections/ImgFrameSection";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import { isApiCategoryEnum, titleifyCategory } from "components/docs/utils/services";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { IntegrationWithSlug } from "types/types";
import styled from "styled-components";
import DeprecatedH2 from "components/deprecated/DeprecatedH2";

interface QueryType {
  allEndpointMapping: {
    nodes: Array<{ integration: IntegrationWithSlug }>;
  };
}

const IntegrationTitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 12px;
`;

/**
 * Creates a page for Merge Link's single integration guide. Has a redirect,
 * see REDIRECTS in gatsby-node for more info.
 */
const SingleIntegrationPage = () => {
  const integrationsData = useStaticQuery<QueryType>(graphql`
    query {
      allEndpointMapping(sort: { fields: integration___slug }) {
        nodes {
          integration {
            name
            slug
            categories
          }
        }
      }
    }
  `);

  const integrations = (integrationsData?.allEndpointMapping?.nodes ?? []).map((node) => {
    const integration = node.integration;
    const enforcedCategories = integration.categories?.filter((category) =>
      isApiCategoryEnum(category),
    );
    return { ...integration, categories: enforcedCategories };
  });

  // Group all integrations by their categories
  const integrationsByCategory: Record<APICategory, IntegrationWithSlug[]> = integrations.reduce(
    (acc: Record<APICategory, IntegrationWithSlug[]>, integration) => {
      integration.categories.forEach((category) => {
        if (!acc[category]) {
          acc[category] = [];
        }

        acc[category].push(integration);
        return acc;
      });
      return acc;
    },
    {} as Record<APICategory, IntegrationWithSlug[]>,
  );

  return (
    <StaticPageContainer>
      <DocsHelmet
        title="Merge Link: Single integration"
        description="Learn how to use Merge Link in your product."
      />
      <HeaderBar
        title="Authorize single integration"
        subtitle="Direct your user to setup the configuration of a single integration."
      />
      <DeprecatedH2 className="mt-1 mb-3">Overview</DeprecatedH2>
      <p className="mb-3">
        You may want to customize your user experience to skip integration selection and guide your
        user directly to a specific integration&apos;s configuration step in Merge&apos;s
        integration authorization component.
      </p>
      <ImgFrameSection>
        <StaticImage
          src="../../../assets/images/docs/diagram/skip-integration-selection.png"
          alt="API Keys preview"
          quality={100}
          placeholder="none"
          layout="constrained"
          style={{ maxWidth: "724px" }}
        />
      </ImgFrameSection>
      <p className="mb-3">
        If you don&apos;t want to display a menu of integrations to your user and instead want to
        show them a single integration in Merge&apos;s component, you can do so using the code
        example below.
      </p>
      <p className="mb-3">
        When you create a link token to initialize a linking flow session (as in Step 1 of the{" "}
        <b>
          <Link to={LINKS.GET_STARTED_LINK.linkTo}>Get Started</Link>
        </b>{" "}
        guide), add the identifier (found below) of the single integration you want to display in
        Merge&apos;s integration authorization component to the <code>link_token</code> request
        parameters.
      </p>
      <p className="mb-6">
        A <code>link_token</code> created with this parameter will cause the linking flow to open
        directly to the integration configuration input form of just that integration.
      </p>

      <CodeExample
        folder="linking-flow/single-integration/single-integration-link-token"
        codeBlockName="Backend - Generate Link Token"
        style={{ maxHeight: "none" }}
      />

      <Row style={{ justifyContent: "left" }}>
        {Object.values(APICategory)
          .filter((category) => category !== APICategory.mktg)
          .map((category) => {
            const categoryIntegrations = integrationsByCategory[category];

            return (
              <Col md={4} xs={12}>
                <IntegrationTitle className="mt-9 mb-6">
                  {titleifyCategory(category)} integration identifiers
                </IntegrationTitle>
                <ul className="mb-9">
                  {categoryIntegrations?.map((integration) => (
                    <li className="mb-3" key={`${category}-${integration.slug}`}>
                      <code>{integration.slug}</code>
                    </li>
                  ))}
                </ul>
              </Col>
            );
          })}
      </Row>
    </StaticPageContainer>
  );
};

export default SingleIntegrationPage;
